@tailwind base;
@tailwind components;

.my-theme-color{
    @apply bg-gradient-to-r from-[#A5FECB] via-[#20BDFF] to-[#5433FF]
}
.my-theme-color-rev{
    @apply bg-gradient-to-r from-[#5433FF] via-[#20BDFF] to-[#A5FECB]
}

.my-space{
    @apply container mx-auto px-5 py-3 w-full
}

.my-section-style{
    @apply min-h-screen flex flex-col justify-center items-center
}

.my-nav-link-hover{
    @apply inline-block relative text-white after:content-[''] after:absolute after:w-full after:scale-x-0 after:h-[2px] after:bottom-0 after:left-0 after:bg-[#A5FECB] after:origin-bottom-right after:transition after:ease-out after:duration-300 hover:after:scale-x-100 hover:after:origin-bottom-left
}

.my-active-link{
    @apply inline-block relative text-white after:content-[''] after:absolute after:w-full after:h-[2px] after:bottom-0 after:left-0 after:bg-[#A5FECB] after:origin-bottom-right after:scale-x-100
}

.my-btn{
    @apply rounded py-2 px-7 transition duration-300 ease-in-out hover:bg-black hover:text-[#20BDFF]
}

.my-service-card{
    @apply aspect-square bg-opacity-70 hover:opacity-100 rounded-lg
}

.my-service-card-animation{
    @apply h-full w-full p-5 gap-3 rounded-lg flex flex-col justify-end text-lg hover:font-bold text-white transition ease-in hover:bg-[#5433FF] hover:bg-opacity-70 duration-300
}

.my-service-card-elements{
    @apply opacity-0 hidden group-hover:block group-hover:animate-tooltip_show
}

.my-service-erp{
    @apply bg-[#A5FECB] bg-opacity-60 border-2 border-[#5433FF] relative p-5 w-full md:w-1/2 lg:w-1/4 rounded-md transition duration-300 hover:bg-[#A5FECB] hover:bg-opacity-100
}

.my-scroll-to-top{
    @apply bg-[#20BDFF]
}

@tailwind utilities;
